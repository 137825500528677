import React from "react";

const AddCollectionButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="border rounded-md flex items-center gap-1 justify-center hover:shadow-md hover:bg-primary-600 hover:text-white hover:border-green-800"
    >
      <span className="material-symbols-outlined text-4xl">new_window</span>{" "}
      <span className="font-medium">Add Collection</span>
    </button>
  );
};

export default AddCollectionButton;
