import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "react-headless-accordion";
import { Tooltip } from "@material-tailwind/react";
import { useSelector } from "react-redux";

const DashboardSidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth);
  const { pathname } = location;

  const menuList = [
    {
      roles: [1, 2, 3, 4],
      title: "Dashboard",
      icon: "dashboard",
      link: "/dashboard",
    },
    {
      roles: [1, 2, 3, 4],
      title: "App Banners",
      icon: "credit_card",
      link: "/dashboard/app-banners/banner-list",
      submenu: [
        {
          roles: [1, 2, 3, 4],
          title: "Video Banners",
          icon: "video_library",
          link: "/dashboard/app-banners/video-banner",
        },
      ]
    },
    {
      roles: [1, 2, 3, 4],
      title: "Notification Management",
      icon: "notifications",
      link: "/dashboard/notification-management/notification-list",
      submenu: [
        // {
        //   roles: [1, 2, 3],
        //   title: "Create Notification",
        //   icon: "notifications_active",
        //   link: "/dashboard/notification-management/create-notification",
        // },
        {
          roles: [1, 2, 3, 4],
          title: "Create Push Notification",
          icon: "notifications_active",
          link: "/dashboard/notification-management/create-push-notification",
        },
        {
          roles: [1, 2, 3, 4],
          title: "Segments",
          icon: "segments",
          link: "/dashboard/notification-management/segments",
        },
      ],
    },
    {
      roles: [3, 1, 4],
      title: "App Settings",
      icon: "settings",
      link: "/dashboard/app-setting",
      submenu: [
        {
          roles: [1, 3, 4],
          title: "Static Sites",
          icon: "language",
          link: "/dashboard/app-setting/static-site",
        },
        {
          roles: [4, 3],
          title: "Logs",
          icon: "manage_search",
          link: "/dashboard/app-setting/app-logs",
        },
        {
          roles: [4, 3],
          title: "Database Backup",
          icon: "storage",
          link: "/dashboard/app-setting/database-logs",
        },
      ]
    },
    {
      roles: [1, 3, 4],
      title: "Staff Management",
      icon: "person_pin",
      link: "/dashboard/user-management/manage-staff",
    },
    {
      roles: [3, 4],
      title: "Mobile Layout Manager",
      icon: "developer_mode",
      link: "/dashboard/mobile-layout-manager",
    },
  ];

  const toggleTab = (link) => {
    navigate(link);
  };

  return (
    <div className="flex flex-col sticky top-0 xl:w-[300px] w-[77px] p-4">
      <div className="flex justify-center py-5">
        <img
          src="/images/logo/grazie-logo.png"
          className="hidden xl:block w-[92px] h-[55px]"
          alt="Grazie logo"
        />
        <img
          src="/images/logo/grazie_logo_512.png"
          className="xl:hidden w-[70px] h-[44px]"
          alt="Grazie logo"
        />
      </div>

      <Accordion>
        {menuList.map((menu, index) => {
          const { title, icon, link, submenu, roles } = menu;
          const menuRoute = pathname.split("/")[2];
          const menuSubRoute = link.split("/")[2];
          const isMenuActive = menuRoute === menuSubRoute;
          const userRoleId = Number(user?.profile?.role_id);
          if (roles.includes(userRoleId)) {
            return (
              <AccordionItem key={`menu_list_accordian_menu${index}`}>
                <AccordionHeader className="w-full">
                  <h6
                    onClick={() => toggleTab(link)}
                    key={`menu_list_${index}`}
                    className={`flex  text-sm  py-2.5 gap-2 items-center text-left px-3 cursor-pointer ${
                      isMenuActive
                        ? "bg-primary-800 text-white"
                        : "hover:bg-primary-300 hover:text-black"
                    }`}
                  >
                    <Tooltip
                      className="xl:hidden border font-medium text-black border-gray-50 bg-white p-2  shadow-xl shadow-black/10 rounded-md mx-3"
                      content={title}
                      placement="right"
                    >
                      <span className="material-icons text-xl">{icon}</span>
                    </Tooltip>
                    <span className="hidden xl:block"> {title}</span>
                    {submenu?.length && (
                      <span className="hidden xl:block material-symbols-outlined">
                        arrow_drop_down
                      </span>
                    )}
                  </h6>
                </AccordionHeader>

                {submenu && Array.isArray(submenu) ? (
                  <AccordionBody>
                    {submenu.map((item, index) => {
                      const { title, icon, link, roles: subMenuRoles } = item;
                      const isSubMenuActive = pathname === link;
                      if (subMenuRoles.includes(userRoleId)) {
                        return (
                          <AccordionItem key={`submenu_accordian_key_${index}`}>
                            <AccordionHeader className="w-full">
                              <h6
                                onClick={() => toggleTab(link)}
                                key={`menu_list_${index}`}
                                className={`flex text-sm py-2 whitespace-nowrap gap-2 items-center text-left px-3 cursor-pointer ${
                                  isSubMenuActive
                                    ? "bg-primary-300 text-black"
                                    : "hover:bg-gray-100"
                                }`}
                              >
                                <Tooltip
                                  className="xl:hidden border font-medium text-black border-gray-50 bg-white p-2  shadow-xl shadow-black/10 rounded-md mx-3"
                                  content={title}
                                  placement="right"
                                >
                                  <span className="material-icons">{icon}</span>
                                </Tooltip>
                                {title}
                              </h6>
                            </AccordionHeader>
                          </AccordionItem>
                        );
                      }
                    })}
                  </AccordionBody>
                ) : null}
              </AccordionItem>
            );
          }
        })}
      </Accordion>
    </div>
  );
};

export default DashboardSidebar;
