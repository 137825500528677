import React from "react";

const ProductCard = () => {
  return (
    <div className="bg-white rounded-md min-w-[150px] min-h-[150px] flex flex-col justify-center items-center text-6xl border">
      <span className="material-symbols-outlined">storefront</span>
      <span className="text-sm font-medium">Product</span>
    </div>
  );
};

export default ProductCard;
