import React, { createContext, useEffect, useState, useRef } from "react";
import FlashSale from "../../components/MobileLayoutManager/FlashSale";
import AddCollectionButton from "../../components/MobileLayoutManager/AddCollectionButton";
import useAxios from "../../hooks/useAxios";
import Select from "react-select";
import { List, arrayMove } from "react-movable";
import CollectionCard from "../../components/MobileLayoutManager/CollectionCard";
import BannerVideoCard from "../../components/MobileLayoutManager/BannerVideoCard";
import BannerImageCard from "../../components/MobileLayoutManager/BannerImageCard";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import Loader from "../../components/Loader/Loader";

export const MobileLayoutManagerContext = createContext({});
const COMPONENT_TYPES = {
  product: "products",
  bannerImage: "image-banner",
  video: "video-banner",
  flashSale: "flash-sale",
};

const genRandomId = () => uuidv4();

const MobileLayoutManager = () => {
  const axios = useAxios();
  const [collectionList, setCollectionList] = useState([]);
  const [components, setComponents] = useState([]);
  const [isCollectionListVisible, setCollectionListVisible] = useState(false);
  const videoFileRef = useRef();
  const imageFileRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const initialRender = useRef(true);
  const [existingLayout, setExistingLayout] = useState([]);
  const toastId = useRef();
  const notifyToast = () =>
    (toastId.current = toast("Publishing Layout...", {
      autoClose: false,
      type: "info",
    }));
  const dismissToast = () => toast.dismiss(toastId.current);

  const fetchLayoutData = async () => {
    setIsDataLoading(true);
    setComponents([]);
    const response = await axios.get("services/getMobileHomeScreenLayout");
    if (response?.data?.status) {
      let data = response?.data?.data || [];
      if (Array.isArray(data) && data.length) {
        data.sort((a, b) => Number(a.index) - Number(b.index));
        setExistingLayout(data);

        data.forEach((item) => {
          if (item.type === COMPONENT_TYPES.product) {
            setComponents((prev) => [
              ...prev,
              {
                component: (
                  <CollectionCard
                    {...{ ...item, randId: item?.collection_id }}
                  />
                ),
                ...item,
                collection: {
                  id: item?.collection_id,
                  title: item?.collection_title,
                  handle: item?.collection_handle,
                },
                randId: item?.collection_id || null,
              },
            ]);
          } else if (item.type === COMPONENT_TYPES.bannerImage) {
            const randId = genRandomId();
            setComponents((prev) => [
              ...prev,
              {
                ...item,
                component: (
                  <BannerImageCard src={item.file_src} randId={randId} />
                ),

                file: null,
                randId: randId,
                collection: item?.collection_id
                  ? {
                      id: item?.collection_id || null,
                      title: item?.collection_title || null,
                      handle: item?.collection_handle || null,
                    }
                  : null,
                product: item?.product_id
                  ? {
                      id: item?.product_id || null,
                      title: item?.product_title || null,
                      handle: item?.product_handle || null,
                    }
                  : null,
              },
            ]);
          } else if (item.type === COMPONENT_TYPES.video) {
            const randId = genRandomId();
            setComponents((prev) => [
              ...prev,
              {
                ...item,
                component: (
                  <BannerVideoCard randId={randId} src={item.file_src} />
                ),
                randId: randId,
                file: null,
                collection: item?.collection_id
                  ? {
                      id: item?.collection_id || null,
                      title: item?.collection_title || null,
                      handle: item?.collection_handle || null,
                    }
                  : null,
                product: item?.product_id
                  ? {
                      id: item?.product_id || null,
                      title: item?.product_title || null,
                      handle: item?.product_handle || null,
                    }
                  : null,
              },
            ]);
          }
        });
      }
    }

    setTimeout(() => {
      setIsDataLoading(false);
    }, 600);
  };

  useEffect(() => {
    if (initialRender.current) {
      fetchLayoutData();
      initialRender.current = false;
    }
  }, []);

  const handleSelectCollection = (e) => {
    const val = { title: e.node.title, randId: e.node.id };
    setComponents((prev) => [
      ...prev,
      {
        component: <CollectionCard {...val} />,
        ...val,
        type: COMPONENT_TYPES.product,
        collection: e.node,
      },
    ]);
    setCollectionListVisible(false);
  };

  const handleRemoveCollectionCard = (e) => {
    setComponents((prev) => prev.filter((i) => i.randId !== e.randId));
  };

  const fetchCollections = async (pageLimit = 500) => {
    setCollectionList([]);
    await axios
      .get(`shopify/getCollections?pageLimit=${pageLimit}`)
      .then((res) => {
        if (res?.data?.status) {
          setCollectionList(res?.data?.collections?.edges || []);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  useEffect(() => {
    fetchCollections();
  }, []);

  const handleValidateVideoFile = (e) => {
    const file = e?.target?.files?.[0];
    if (!file) {
      toast.error("Something went wrong while uploading file, Try again.");
      videoFileRef.current.value = "";
      return;
    }
    const { size } = file;
    if (size > 5253880) {
      toast.error(
        "File size is greater than 5MB, Try uploading video file upto 5MB"
      );
      videoFileRef.current.value = "";
      return;
    }
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      const randId = genRandomId();
      setComponents((prev) => [
        ...prev,
        {
          component: (
            <BannerVideoCard randId={randId} src={fileReader.result} />
          ),
          title: "Video Banner",
          randId: randId,
          type: COMPONENT_TYPES.video,
          file,
          src: fileReader.result,
          collection: "",
          product: "",
        },
      ]);
      videoFileRef.current.value = "";
    };
  };

  const handleChangeImgInput = (e) => {
    const inputFile = e?.target?.files?.[0] || null;
    if (inputFile) {
      const { size, type } = inputFile;
      const fileSizeLimit = 1024000;
      const allowedFileType = /image/;
      const isValidImageFile = allowedFileType.test(type);
      if (isValidImageFile) {
        if (size < fileSizeLimit) {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(inputFile);
          fileReader.onload = () => {
            const randId = genRandomId();
            setComponents((prev) => [
              ...prev,
              {
                component: (
                  <BannerImageCard src={fileReader.result} randId={randId} />
                ),
                type: COMPONENT_TYPES.bannerImage,
                title: "Image Banner",
                file: inputFile,
                randId: randId,
                collection: "",
                product: "",
              },
            ]);
          };
        } else {
          toast.error(
            "Uploaded image file is too large, try uploading image less than 1 MB"
          );
        }
      } else {
        toast.error(
          "Uploaded file is not a valid image file, Only image files are allowed"
        );
      }
    }
    imageFileRef.current.value = "";
  };
  const handleSetCollection = (e, value) => {
    const { node } = e;
    setComponents((prev) =>
      prev.map((i) => {
        if (i.randId === value) {
          return {
            ...i,
            collection: node,
            product: "",
          };
        }
        return i;
      })
    );
  };
  const handleSetProduct = (e, value) => {
    const { node } = e;
    setComponents((prev) =>
      prev.map((i) => {
        if (i.randId === value) {
          return {
            ...i,
            product: node,
          };
        }
        return i;
      })
    );
  };

  const insertLayoutData = async (formData) => {
    const response = await axios.post(
      "services/mobileHomeScreenLayout",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  };

  const deleteExistingLayout = async (idsToDelete) => {
    const response = await axios.post("services/deleteMobileHomeScreenLayout", {
      idsToDelete,
    });
    return response;
  };

  const onSubmit = async () => {
    let isDataChanges = false;
    try {
      setIsLoading(true);

      const mobileLayOutData = [
        {
          index: 0,
          title: "FLASH SALE",
          collection_id: "",
          collection_title: "",
          type: COMPONENT_TYPES.flashSale,
          product_id: "",
          product_title: "",
          file: null,
          id:
            existingLayout?.find?.(
              (i) => i?.type === COMPONENT_TYPES?.flashSale
            )?.id || null,
          collection_handle: "",
          product_handle: "",
        },
      ];
      components.forEach((i, o) => {
        mobileLayOutData.push({
          index: o + 1,
          title: i?.title,
          collection_id: i?.collection?.id || "",
          collection_title: i?.collection?.title || "",
          collection_handle: i?.collection?.handle || "",
          type: i.type,
          product_id: i?.product?.id || "",
          product_title: i?.product?.title || "",
          product_handle: i?.product?.handle || "",
          file: i?.file || null,
          id: i?.id || null,
        });
      });

      const idsToDelete = [];
      const data = [];
      existingLayout.forEach((item) => {
        const isExists = !!mobileLayOutData.find(
          (i) => Number(i.id) === Number(item.id)
        );
        if (!isExists) {
          idsToDelete.push(Number(item.id));
        }
      });
      mobileLayOutData.forEach((item) => {
        const getDataInCurrentLayout = existingLayout.find(
          (i) => Number(i.id) === Number(item.id)
        );
        if (getDataInCurrentLayout) {
          const { index, collection_id, product_id } = getDataInCurrentLayout;
          // data is exists in the current layout -> check for changes ?
          const isIndexNotSame = Number(item.index) !== Number(index);
          const isFileUploaded = item?.file;
          const isCollectionNotSame = item?.collection_id !== collection_id;
          const isProductNotSame = item?.product_id !== product_id;
          const isDataUpdated =
            isIndexNotSame ||
            isFileUploaded ||
            isCollectionNotSame ||
            isProductNotSame;
          if (isDataUpdated) {
            data.push({
              ...item,
              mode: "update",
            });
          }
        } else {
          // data is not exist in the current layout so insert this data
          data.push({
            ...item,
            mode: "insert",
          });
        }
      });

      if (idsToDelete.length || data.length) {
        notifyToast();
      }

      if (idsToDelete.length) {
        isDataChanges = true;
        await deleteExistingLayout(idsToDelete);
      }

      if (data.length) {
        isDataChanges = true;
        await Promise.all(
          data.map((i) => {
            const formData = new FormData();
            Object.keys(i).forEach((v) => {
              formData.append(v, i[v]);
            });
            return insertLayoutData(formData);
          })
        );
      }
    } catch (err) {
      toast.error(
        err?.message || "Something went wrong while publishing the layout"
      );
    } finally {
      setIsLoading(false);
      if (isDataChanges) {
        setTimeout(() => {
          dismissToast();
          toast.success("Layout updated successfully.");
        }, 500);
        fetchLayoutData();
      } else {
        toast.info("No changes detected");
      }
    }
  };

  return (
    <MobileLayoutManagerContext.Provider
      value={{
        handleRemoveCollectionCard,
        COMPONENT_TYPES,
        collectionList,
        components,
        handleSetCollection,
        handleSetProduct,
      }}
    >
      <div className="flex p-10 justify-center bg-gray-50">
        {/* Mobile Privew Starts */}
        <div className="flex bg-white flex-col items-center gap-1 border rounded-lg shadow-md mr-16">
          <img
            src="/images/device_preview/ios_preview.jpg"
            alt="android device"
            className="w-fit rounded-t-3xl"
          />
          {isDataLoading ? (
            <div className="mobile-layout-manager-container w-[425px] bg-white flex justify-center items-center">
              <div className="flex flex-col gap-2 items-center">
                <Loader />
                <span>
                  {isLoading
                    ? "Publishing Layout..."
                    : isDataLoading
                    ? "Loading..."
                    : ""}
                </span>
              </div>
            </div>
          ) : (
            <div className="bg-white pb-5 px-5 w-[425px] overflow-y-auto flex flex-col gap-5">
              <FlashSale />
              <List
                lockVertically
                values={components}
                onChange={({ oldIndex, newIndex }) =>
                  setComponents(arrayMove(components, oldIndex, newIndex))
                }
                renderList={({ children, props }) => (
                  <ul {...props} className="hover:cursor-grab">
                    {children}
                  </ul>
                )}
                renderItem={({ value, props, isDragged }) => (
                  <li
                    className={`list-none py-1 ${
                      isDragged && "cursor-grabbing"
                    }`}
                    {...props}
                  >
                    {value.component}
                  </li>
                )}
              />
              {isCollectionListVisible && (
                <div className="border rounded-md p-3 border-gray-400 shadow-md">
                  <div className="text-right">
                    <span
                      className="text-red-600 material-symbols-outlined cursor-pointer"
                      onClick={() => setCollectionListVisible(false)}
                    >
                      close
                    </span>
                  </div>
                  <Select
                    placeholder="Select collection"
                    className="w-full"
                    options={collectionList.filter(
                      (p) => !!!components.find((v) => v.randId === p.node.id)
                    )}
                    getOptionLabel={(o) => o.node.title}
                    getOptionValue={(o) => o.node.id}
                    onChange={handleSelectCollection}
                  />
                </div>
              )}
              <AddCollectionButton
                onClick={() => setCollectionListVisible(true)}
              />
              <label
                htmlFor={`input-banner-image`}
                className="flex gap-1 border rounded-md items-center justify-center cursor-pointer hover:shadow-md hover:border-green-800 hover:bg-primary-600 hover:text-white"
              >
                <span className="material-symbols-outlined text-4xl">
                  add_photo_alternate
                </span>{" "}
                <span className="font-medium">Add Image Banner</span>
              </label>
              <input
                id={`input-banner-image`}
                onChange={handleChangeImgInput}
                type="file"
                hidden
                accept="image/*"
                ref={imageFileRef}
              />

              <div>
                <label
                  htmlFor="video-banner"
                  className="flex gap-1 border rounded-md items-center justify-center cursor-pointer hover:shadow-md hover:border-green-800 hover:bg-primary-600 hover:text-white"
                >
                  <span className="material-symbols-outlined text-4xl">
                    smart_display
                  </span>{" "}
                  <span className="font-medium">Add Video Banner</span>
                </label>
                <input
                  id="video-banner"
                  onChange={handleValidateVideoFile}
                  type="file"
                  hidden
                  accept="video/*"
                  ref={videoFileRef}
                />
              </div>
            </div>
          )}
        </div>
        {/* Mobile Privew Ends */}

        {/* Layout Manager Starts */}
        <button
          disabled={isLoading}
          onClick={onSubmit}
          className={`btn sticky top-3 ${isLoading && "opacity-50"}`}
        >
          Publish Layout
        </button>
        {/* Layout Manager Ends */}
      </div>
    </MobileLayoutManagerContext.Provider>
  );
};

export default MobileLayoutManager;
