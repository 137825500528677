import React, { useEffect, useRef, useState } from "react";
import { DatePicker, Select, Table, Tag, Modal, Flex, Progress } from "antd";
import useAxios from "../../hooks/useAxios";
import LoadingButton from "../../components/LoadingButton";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/Loader";
import formatDate from "../../utils/FormatDateAndTime/formatDate";

const dbBackupStatusTypes = {
  pending: "Pending",
  failed: "Failed",
  completed: "Completed",
};

const storagePercentageColors = {
  "0%": "#4CAF50",
  "50%": "#FFEB3B",
  "75%": "#FF9800",
  "100%": "#F44336",
};

const DatabaseLogs = () => {
  const [backupBtnLoader, setBackupBtnLoader] = useState(false);
  const [backupInProgressLoader, setBackupInProgressLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [viewDetailsModal, setViewDetailsModal] = useState({
    open: false,
    data: "",
  });
  const [storageInfo, setStorageInfo] = useState({});
  const axios = useAxios();
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [dbLogList, setDbLogList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const fetchDatabaseLogs = async () => {
    setTableLoader(true);
    const response = await axios
      .get(`services/dbBackupList?pageNo=${pageNo}&pageSize=${pageSize}`)
      .then((res) => res)
      .catch((err) => err)
      .finally(() => {
        setTimeout(() => {
          setTableLoader(false);
        }, 600);
      });
    setDbLogList(response?.data?.data || []);
    setTotalRecords(Number(response?.data?.totalRecords || 0));
  };

  const fetchStorageDetails = async () => {
    const response = await axios
      .get("files/getStorageInfo")
      .then((res) => res)
      .catch((err) => err);
    setStorageInfo(response?.data?.result || {});
  };

  const handleViewDetailsModal = (status, details) => {
    setViewDetailsModal((prev) => ({
      ...prev,
      open: status,
      data: status ? details : "",
    }));
  };
  const handleDbBackup = async () => {
    setBackupBtnLoader(true);
    setBackupInProgressLoader(true);
    await axios
      .get("services/dbBackup")
      .then((res) => res)
      .catch((err) => err)
      .finally(() => {
        setTimeout(() => {
          setBackupBtnLoader(false);
        }, 1000);
        setTimeout(() => {
          setBackupInProgressLoader(false);
        }, 10000);
      });
  };
  const downloadDbBackupFile = (id) => {
    const button = document.getElementById(`download-file-${id}`);
    const loaderBtn = document.getElementById(`download-file-loader-${id}`);
    button.classList.add("hidden");
    loaderBtn.classList.remove("hidden");
    setTimeout(async () => {
      const response = await axios
        .get(`services/downloadDbBackup?id=${id}`, {
          responseType: "blob",
        })
        .then((res) => res)
        .catch((err) => err)
        .finally(() => {
          button.classList.remove("hidden");
          loaderBtn.classList.add("hidden");
        });
      if (response.status === 200) {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "grazie_db");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      } else {
        toast.error(
          "Something went wrong while downloading the file, Try again later."
        );
      }
    }, 600);
  };

  const columns = [
    {
      title: "Start Time",
      dataIndex: "start_time",
      key: "start_time",
      render: (_, { start_time }) => (
        <div className="flex gap-2">
          <span>{formatDate(start_time).dateString2}</span>
          <span>{formatDate(start_time).localString2}</span>
        </div>
      ),
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      key: "end_time",
      render: (_, { end_time }) => (
        <div className="flex gap-2">
          <span>{formatDate(end_time).dateString2}</span>
          <span>{formatDate(end_time).localString2}</span>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => {
        let color;
        if (status === dbBackupStatusTypes.completed) {
          color = "green";
        } else if (status === dbBackupStatusTypes.failed) {
          color = "red";
        } else {
          color = "yellow";
        }
        return (
          <Tag color={color} key={status}>
            {status}
          </Tag>
        );
      },
    },
    {
      title: "Details",
      dataIndex: "action_details",
      key: "action_details",
      render: (_, { action_details }) => {
        return (
          <Tag
            className="cursor-pointer"
            onClick={() => handleViewDetailsModal(true, action_details)}
            color={"blue"}
            key={action_details}
          >
            View Details
          </Tag>
        );
      },
    },
    {
      title: "Execution Type",
      dataIndex: "execution_type",
      key: "execution_type",
      render: (_, { execution_type }) => {
        let color;
        if (execution_type === "Automated") {
          color = "purple";
        } else {
          color = "orange";
        }
        return (
          <Tag color={color} key={execution_type}>
            {execution_type}
          </Tag>
        );
      },
    },
    {
      title: "",
      dataIndex: "download",
      key: "download",
      render: (_, { id }) => {
        return (
          <>
            <button
              id={`download-file-${id}`}
              onClick={() => downloadDbBackupFile(id)}
              className=""
            >
              <span className="material-symbols-outlined">download</span>
            </button>
            <div className="hidden" id={`download-file-loader-${id}`}>
              <Loader height="25" width="25" color={"#179633"} />
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    fetchDatabaseLogs();
    fetchStorageDetails();
  }, [pageNo, pageSize]);

  function formatBytes(bytes) {
    if (!bytes) {
      return "NA";
    }
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Bytes";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    return (bytes / Math.pow(1024, i)).toFixed(2) + " " + sizes[i];
  }

  function calculatePercentage(used, total) {
    if (!used || !total) {
      return 0;
    }
    return ((used / total) * 100).toFixed(2);
  }

  const usedStorage = formatBytes(storageInfo?.used);
  const totalStorage = formatBytes(storageInfo?.allocation?.allocated);
  const allocationType =
    storageInfo?.allocation?.[Object.keys(storageInfo?.allocation)?.[0]] ||
    "NA";
  const percentageOfUsage = calculatePercentage(
    storageInfo?.used,
    storageInfo?.allocation?.allocated
  );
  return (
    <div className="">
      <Modal
        title="Log Details"
        open={viewDetailsModal.open}
        footer={false}
        onCancel={() => handleViewDetailsModal(false)}
      >
        <div>{viewDetailsModal.data}</div>
      </Modal>
      <div className="flex  justify-between p-5 border-b">
        <div className="flex flex-col gap-2">
          <h1 className="font-medium text-xl">Database Logs</h1>
          <div className="flex flex-col justify-center text-sm border rounded-md p-2 shadow-sm">
            <p className="font-medium ">Storage Details</p>
            <div className="flex items-center gap-2 py-2">
              <span className="flex flex-col">
                <span>Type: {allocationType}</span>
                <span>Used: {usedStorage}</span>
                <span>Total: {totalStorage}</span>
              </span>
              <Progress
                type="circle"
                size={40}
                percent={percentageOfUsage}
                strokeColor={storagePercentageColors}
                //status="exception"
                //format={(percent) => `${percent} Days`}
              />
            </div>
          </div>
        </div>
        {backupInProgressLoader && (
          <div className="border flex gap-2 items-center shadow-md border-green-300 bg-green-100 p-4">
            <Loader width="45" height="45" />
            <div>
              <h1 className="font-medium">
                Database backup is processing in the background.
              </h1>
              <h2>
                This may take some time. Reload the page after some time to see
                the details.
              </h2>
            </div>
          </div>
        )}
        {backupBtnLoader ? (
          <LoadingButton title="Creating Backup..." />
        ) : (
          <button onClick={handleDbBackup} className="btn">
            Create Backup
          </button>
        )}
      </div>
      {/* <div className="p-5 flex items-center gap-3">
        <DatePicker />
        <Select />
      </div> */}
      <div className="p-5">
        <Table
          loading={tableLoader}
          pagination={{
            current: pageNo,
            pageSize: pageSize,
            responsive: true,
            defaultCurrent: 1,
            defaultPageSize: 10,
            total: totalRecords,
            showTotal: (total) => `Total ${total} items`,
            onChange: (pagenumber) => setPageNo(pagenumber),
          }}
          dataSource={dbLogList}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default DatabaseLogs;
