import React from "react";
import ProductCard from "./ProductCard";

const FlashSale = () => {
  return (
    <div className="flex flex-col gap-2 bg-green-300 p-3 rounded-md border border-gray-400 shadow-md">
      <div className="text-center font-bold text-lg">FLASH SALE⚡</div>
      <div className="flex justify-evenly gap-3">
        <ProductCard />
        <ProductCard />
      </div>
      <div className="flex justify-evenly gap-3">
        <ProductCard />
        <ProductCard />
      </div>
    </div>
  );
};

export default FlashSale;
