import React from "react";
import ProductCard from "./ProductCard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const ProductListSwiper = (props) => {
  const data = new Array(10).fill(<ProductCard />);
  return (
    <div className="swiper-outer relative overflow-hidden ">
      <Swiper slidesPerView={"auto"} updateOnWindowResize>
        {data.map((i, o) => (
          <SwiperSlide key={o} className="max-w-[150px] mr-2">
            {i}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ProductListSwiper;
