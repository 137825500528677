import React, { useContext, useEffect, useState } from "react";
import { MobileLayoutManagerContext } from "../../containers/MobileLayoutManager/MobileLayoutManager";
import useAxios from "../../hooks/useAxios";
import Select from "react-select";
import Loader from "../Loader/Loader";

const BannerVideoCard = (props) => {
  const {
    handleRemoveCollectionCard,
    collectionList,
    components,
    handleSetCollection,
    handleSetProduct,
  } = useContext(MobileLayoutManagerContext);
  const axios = useAxios();
  const [collectionProductList, setCollectionProductList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { randId, src } = props;
  const data = components.find((i) => i.randId === randId) || {};
  const { collection, product } = data;

  const fetchCollectionProducts = async (controller) => {
    if (!collection?.id || collectionProductList.length) {
      setIsLoading(false);
      return;
    }
    await axios
      .get(`shopify/collectionProducts?collectionId=${collection.id}`, {
        signal: controller.signal,
      })
      .then((res) => {
        if (res?.data?.status) {
          setCollectionProductList(res?.data?.results || []);
        }
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!collection?.id) {
      return;
    }
    setIsLoading(true);
    const controller = new AbortController();
    const delayTimer = setTimeout(() => {
      fetchCollectionProducts(controller);
    }, 600);
    return () => {
      clearTimeout(delayTimer);
      controller.abort();
    };
  }, [collection]);

  const handleSelectCollection = (e) => {
    handleSetCollection(e, randId);
    setCollectionProductList([]);
  };
  const handleSelectProduct = (e) => {
    handleSetProduct(e, randId);
  };

  return (
    <div className="p-5 border shadow-md rounded-md bg-white flex flex-col border-gray-400">
      <div className="flex justify-end mb-3">
        <button
          className="border rounded-md flex items-center justify-center p-0.5"
          onClick={() => handleRemoveCollectionCard({ randId })}
        >
          <span className="text-red-600 material-symbols-outlined">close</span>
        </button>
      </div>
      <video width="350" controls>
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="flex flex-col mt-3 border p-2">
        <label className="font-medium">CTA</label>
        <div className="flex flex-col gap-1 mt-2">
          <label>Link to collection</label>
          <Select
            menuPlacement="top"
            value={{
              node: collection || { title: "Select collection", id: null },
            }}
            placeholder="Select collection"
            className="w-full"
            options={collectionList}
            getOptionLabel={(o) => o?.node?.title}
            getOptionValue={(o) => o?.node?.id}
            onChange={handleSelectCollection}
          />
        </div>
        {isLoading ? (
          <div className="mt-2 flex justify-center items-center font-medium text-sm gap-1">
            <Loader height="30" width="30" /> Loading products...
          </div>
        ) : !!collectionProductList.length ? (
          <div className="flex flex-col gap-1 mt-2">
            <label>Link to product</label>
            <Select
              menuPlacement="top"
              value={{ node: product || { title: "Select product", id: null } }}
              placeholder="Select product"
              className="w-full"
              options={collectionProductList}
              getOptionLabel={(o) => o?.node?.title}
              getOptionValue={(o) => o?.node?.id}
              onChange={handleSelectProduct}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default BannerVideoCard;
