import React, { useContext } from "react";
import ProductListSwiper from "./ProductListSwiper";
import { MobileLayoutManagerContext } from "../../containers/MobileLayoutManager/MobileLayoutManager";

const CollectionCard = (props) => {
  const { handleRemoveCollectionCard } = useContext(MobileLayoutManagerContext);

  return (
    <div className="flex flex-col gap-2 p-3 rounded-md border bg-white border-gray-400 shadow-md">
      <div className="flex items-center justify-between">
        <span className="font-bold text-lg whitespace-nowrap">
          {props?.title}
        </span>
        <button
          className="border rounded-md flex items-center justify-center p-0.5"
          onClick={() => handleRemoveCollectionCard(props)}
        >
          <span className="text-red-600 material-symbols-outlined">close</span>
        </button>
      </div>

      <ProductListSwiper />
    </div>
  );
};

export default CollectionCard;
